import './Footer.scss'

export default function Footer() {
  return (
    <section className='container Footer'>
        <div>
        Copyright © Anne Clarin 2023
        </div>
    </section>
  )
}
